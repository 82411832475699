$color-sea: #359ead;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  svg {
    height: 20px;
    fill: $color-sea;
    display: inline-block;
  }
}
