@import '../ChakraTheme/styles.scss';

.container {
  border: 2px solid #A8A5A3;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
}

.active {
  border-color: $color-sea;
  padding-bottom: 0;
}

.input {
  padding: 6px 15px;
  width: 100%;
  display: block;
  outline: 0;
  border: none;
  border-radius: 5px;
  line-height: 1.4;
  font-size: $font-size-24px;
  font-family: $font-family-base;
  background: 'white';
  appearance: none;
  cursor: pointer;
  color: $color-font-base;
}
