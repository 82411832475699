@import '../ChakraTheme/styles.scss';

.FilePreview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-10px;
  border: 1px $color-medium-gray-brighter;
  border-style: solid none;

  .image {
    margin-left: $space-10px;
    height: $space-50px;
    width: $space-50px;
    flex-shrink: 0;
  }

  .name {
    margin-left: $space-10px;
    color: $color-dove-gray;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info {
    display: flex;
    align-items: center;
    stroke: $color-sea;
    overflow: hidden;
  }

  .iconWrapper {
    height: $space-20px;
    width: $space-20px;
    min-width: $space-20px;
    fill: $color-sea;
  }

  .icon {
    max-height: $space-20px;
    width: 100%;
    fill: $color-sea;
  }

  .iconClose {
    max-height: $space-20px;
    width: 100%;
    fill: $color-sea;
    cursor: pointer;
  }
}
