@import '../ChakraTheme/styles.scss';

.FileInput {
  .iconWrapper {
    height: $space-20px;
    width: $space-20px;
    fill: $color-sea;
    margin-right: $space-10px;
  }

  .input {
    display: flex;
    width: 100%;
    height: $space-100px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    stroke: $color-sea;
    color: $color-sea;
    border: 2px solid $color-sea-brighter;
    border-radius: $space-10px;
    input {
      display: none;
    }
  }

  * + .input {
    margin-top: 30px;
  }

  .inputDisabled {
    display: flex;
    width: 100%;
    height: $space-100px;
    cursor: default;
    justify-content: center;
    align-items: center;
    stroke: $color-dove-gray;
    color: $color-dove-gray;
    border: 2px solid $color-dove-gray;
    border-radius: $space-10px;
    input {
      display: none;
    }
  }

  .input:hover {
    border-color: $color-sea;
  }

  .error {
    display: flex;
    width: 100%;
    height: $space-50px;
    padding: $space-10px;
    margin-bottom: $space-10px;
    margin-top: $space-30px;
    align-items: center;
    color: $color-error-red;
    border: 2px solid $color-error-red;
    border-radius: $space-10px;
  }

  .errorMessage {
    color: $color-error-red;
  }

  .iconError {
    fill: $color-error-red;
    stroke: none;
  }

  .inputText {
    margin-left: $space-10px;
  }
}
