@import 'shared/components/chakraComponents/ChakraTheme/styles.scss';

@mixin icon {
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  width: $space-50px;
  height: $space-50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-gray-transparent;

  @include respond-to(desktop) {
    &:hover {
      background-color: $color-medium-gray;
    }
  }
}

.VideoController {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 12.5%;
  right: 11%;

  &__icons {
    display: flex;
    justify-content: center;
    margin-bottom: 50%;
  }

  &__controls {
    display: flex;
    gap: 65px;

     button {
      border: none;
       height: 66px;
       width: 66px;
     }
  }

  .end-call:hover {
    background: $color-salmon-brighter;
  }
  .call-mobile:hover {
    background: $color-sea;
  }

  &__icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: $space-10px;
  }

  &__icon_microphone {
    @include icon;
    svg {
      @include bounding-box;
      g {
        stroke: white;
      }
    }
  }

  &__icon_camera {
    @include icon;
    svg {
      height: 15px;
      g {
        stroke: white;
      }
    }

    &--is-disabled {
      svg {
        height: 22px;
      }
    }
  }

  &__icon_settings {
    @include icon;
    svg {
      height: 24px;
      g {
        stroke: white;
      }
    }
  }
}
