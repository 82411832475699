@import 'shared/components/chakraComponents/ChakraTheme/styles.scss';

.input {
  padding: 6px 0 6px 5px;
  border-bottom-color: $color-medium-gray-brighter;
  border-bottom-width: 2px;
  background: none;
  appearance: none;
  cursor: pointer;
  color: $color-dark-gray-brighter;
  outline: 0;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
  cursor: pointer;
}
