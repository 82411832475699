$color-sea: #359ead;
$font-weight-medium: 500;

.container {
  border-radius: 1rem;
  border-width: 2px;
  border-color: $color-sea;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: $font-weight-medium;
  font-size: 14px;
  background: white;
  color: $color-sea;

  &:hover {
    background: $color-sea;
    color: white;
    svg {
      fill: white;
    }
  }
}

.containerActive {
  background: $color-sea;
  color: white;
}

.icon {
  width: 15px;
  margin-left: 5px;
  fill: $color-sea;
}

.iconActive {
  fill: white;
}
