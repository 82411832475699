@import 'shared/components/chakraComponents/ChakraTheme/styles.scss';

.WaitingForPeer {
  display: flex;
  height: 100%;
  width: 100%;
  background: $color-dark-gray-brighter; //#333333
  flex-direction: column;
  align-items: center;

  &__content {
    position: absolute;
    left: 5%;
    flex-direction: column;
    text-align: center;
    color: white;
    max-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 3%;
  }
  &__title {
    color: white;
    font-weight: 500;
    padding-top: 20px;
  }
}

.css-ol3i12 {
  stroke: $color-sea !important;
}

.css-167tgvc {
  stroke: #777676 !important;
}

.space {
  padding-top: 20%;

  @media only screen and (max-width: 1332px) {
   padding-top: 15%;
  }
  @media only screen and (max-width: 1200px) {
    padding-top: 6.5%;
  }

}
