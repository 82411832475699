@import 'shared/components/chakraComponents/ChakraTheme/styles.scss';

@mixin icon {
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  width: $space-50px;
  height: $space-50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-gray-transparent;

  &:hover {
    background-color: $color-medium-gray;
  }
}

.Video {
  width: 100%;
  height: 100%;
  position: relative;
  //background-color: $color-dark-gray;

  @include respond-to(mobile) {
    padding-bottom: $space-10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    // added to prevent scrolling on mobile view?
    html {
      overflow: hidden;
    }
    body {
      overflow-x: hidden;
      position: relative;
      margin: 0;
      padding: 0;
    }
  }

  &__name {
    position: absolute;
    top: 3%;
    left: 45%;
    z-index: 1;
  }
  &__output {
    position: absolute;
    top: 8%;
    left: 45%;
    z-index: 1;
  }
  &__local-participant {
    position: absolute;
    width: 230px;
    height: 226px;
    top: 10%;
    right: 12.5%;
    z-index: 1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $space-10px;
      box-shadow: 0 0 10px -3px $color-dark-gray;
    }

    @include respond-to(mobile) {
      width: 150px;
      height: 140px;
    }
  }

  &__remote-participant {
    margin-left: 64px;
    width: 53.5%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
   
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__remote-participant--is-full-screen {

    width: 100vw;
    height: 100vh;
    margin-left: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;

    video {
      object-fit: cover;
    }

  }

  &__picture-in-picture {
    position: fixed;
    left: 65%;
    bottom: 30%;
    z-index: 100;

    video {
      object-fit: cover;
      max-width: 100%;
      height: 330px;
      position:relative;
    }
  }
  &__pip-end-call{
    position: absolute;
    bottom: 10px;
    right: 170px;

      button {
        border: none
      }
  }

  &__remote-participant--is-hidden {
    display: none;
  }

  &__spacing {
    display: flex;
    flex: 1;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
  }

  &__icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: $space-20px;
  }

  &__icon_view {
    @include icon;
    position: absolute;
    top: $space-20px;
    right: $space-20px;
    z-index: $overlay-action-z-index;

    svg {
      fill: $color-sea;
      display: inline-block;
    }
  }

  &__timer {
    position: absolute;
    left: 20px;
    top: 25px;
    color: white;
  }

  &__leave {
    text-align: center;
    &__wrapper {
      max-width: 600px;
      width: 100%;
    }
    &_text {
      color: white;
    }
  }


}
