@import 'shared/components/chakraComponents/ChakraTheme/styles.scss';

.PatientLeft {
    display: flex;
    height: 100%;
    width: 100%;
    background: $color-dark-gray-brighter; //#333333
    flex-direction: column;
    align-items: center;

&__content {
    position: absolute;
    left: 5%;
    flex-direction: column;
    text-align: center;
    color: white;
    max-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 3%;
}

  &__circle {
    display: flex;
    width: 242px;
    height: 242px;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
    border: 6px solid gray;
    border-radius: 50%;
  }

&__title {
     color: white;
     font-weight: 500;
     padding-top: 15px;
 }
}

.space {
    padding-top: 13%;

@media only screen and (max-width: 1332px) {
    padding-top: 14%;
}
@media only screen and (max-width: 1200px) {
    padding-top: 14.5%;
}
}
