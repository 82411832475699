$font-family-base: 'CentraNo2', 'Helvetica';
$color-font-base: #333333;
$font-weight-book: 400;
$color-sea: #359ead;
$font-weight-medium: 500;

body {
  margin: 0;
  color: $color-font-base;
  font-family: $font-family-base;
  font-weight: $font-weight-book;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// reset text styles
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $font-family-base;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

a {
  color: $color-sea;
  font-weight: $font-weight-medium;
}
