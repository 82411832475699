@import '../ChakraTheme/styles.scss';

.root {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.label {
  color: $color-medium-gray;
}

.wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-medium-gray;
}

.input {
  width: 100%;
  display: block;
  padding: 6px 0;
  outline: 0;
  border: none;
  line-height: 1.4;
  font-size: $font-size-16px;
  color: $color-font-brighter;
  font-family: $font-family-base;
  background: none;
  appearance: none;
  padding-right: $space-30px;
  cursor: pointer;
  color: $color-sea;
}

.icon {
  @include bounding-box;
  width: 20px;
  fill: $color-sea;
  margin-left: -$space-20px;
  pointer-events: none;
}
